/**  =====================
      Editor css start
==========================  **/

.ace_editor {
    width: 100% !important;
    position: relative !important;
    margin-top: 20px;
}

.long-press:focus {
    outline-color: $primary-color;
}

.inputor:focus {
    border-color: $primary-color;
}

#edui1 {
    width: auto !important;
}


/**====== Editor css end ======**/
