/**

Template Name: Gradient Able Admin Template
Author: #
Email: #
File: style.scss
**/


/* =============
  == Table of Contents==

	- Custom Css
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Icons, Accordion, Button, Redial, floating actions, FAB, tabs, typography, buttons, box shadow, slider page, error page, Breadcrumb, pagination, Basic card, Login card
	- General Elements
		-	Modal, Nestable, Lable & Badges, List, Portlates, Preloader, Calender, To-Do, Materialize general elements, Lables
	- Advance Form Elements
	- Pages
		-	Loader page, Invoice, Tasklist, Task detail, Range-slider, Counter, Maintenance, Color, FAQ, Progress, Panels & wells, Contact card, Auth pages, Flag
		-	Gallery, Products pages, Widget, Email, Social timeline, Datatable, Chat, Blog, Wizard, CRM dashboard, Task board, Issue list, Chartlist, X-editable
		-	Home chat, Dashboard 1-2-3-4
	- Responsive media class

============= */

// General theme contents
@import "partials/variables";
@import "partials/mixins";
@import "partials/general";
@import "partials/generic";
// Menu
@import "partials/menu/pcmenu";
// Important Theme elements
@import "partials/theme-elements/button";
@import "partials/theme-elements/form-elements";
// Theme elements
@import "partials/theme-elements/label-badges";
@import "partials/theme-elements/icon-layout";
@import "partials/theme-elements/ready-to-use";
@import "partials/theme-elements/box-shadow";
// Tables
@import "partials/tables/tables";
// Widget
@import "partials/widget-dashboard/widget";
@import "partials/widget-dashboard/dashboard";
// Other
@import "partials/other/alert";
@import "partials/other/authentication";
@import "partials/other/breadcrumb";
@import "partials/other/charts";
@import "partials/other/chat";
@import "partials/other/color";
@import "partials/other/draggable";
@import "partials/other/e-payment";
@import "partials/other/editors";
@import "partials/other/fb-wall";
@import "partials/other/full-calender";
@import "partials/other/job-find";
@import "partials/other/mail-pages";
@import "partials/other/maps";
@import "partials/other/navbars";
@import "partials/other/note";
@import "partials/other/prism";
@import "partials/other/progress-bar";
@import "partials/other/pre-loader";
@import "partials/other/range-slider";
@import "partials/other/rating";
//To-do
@import "partials/todo/todo";
// Responsive css
@import "partials/responsive/responsive";
// dark layout css
@import "partials/dark";

//angular custom
@import "partials/custom";

@import '~ngx-bar-rating/themes/br-default-theme';
@import '~ngx-bar-rating/themes/br-bootstrap-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-o-theme';
@import '~ngx-bar-rating/themes/br-horizontal-theme';
@import '~ngx-bar-rating/themes/br-vertical-theme';
@import '~ngx-bar-rating/themes/br-stars-theme';
@import '~ngx-bar-rating/themes/br-movie-theme';
@import '~ngx-bar-rating/themes/br-square-theme';
@font-face {
    font-family: 'SCG-Reg';
    src: url('./../assets/Fonts/SCG-Reg.woff') format('woff'), /* Pretty Modern Browsers */
  }


*{
	font-family: 'SCG-Reg', sans-serif;
}
.modal-lg, .modal-xl {
    max-width: 90%;
}
.modal-l {
  max-width: 60%;
}
.common-img-bg-SGC {
    background-size: cover;
    background: linear-gradient(45deg, #FFFF, #FFFF);
    height: 100%;
}
.modal-header-red .modal-header.ng-star-inserted {
	background-color: rgb(209, 59, 59);
	color: white;
  }
.container-full{
	max-width: 100% !important;
}
.btn-sm {
    font-size: 14px !important;
}
.table.ColorThRed thead th {
  padding: 0.45rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background-color: rgb(209, 59, 59);
  color: white;
}
.header-group{
	font-size: 18px !important;
	font-weight: bold;
}
.swal-text-align-left div#swal2-content{
    text-align: left !important;
}
.well-dark{
	background-color:#E8E9EC ;
	border-color: #CECFD0;
}
.well-light{
	background-color:white ;
	border-color: #CECFD0;
}
.scroll {
	max-width: 100%;
	overflow-y: auto;
  }
  .hide-header-tab .nav-item {
	width: calc(100% / 6);
	text-align: center; 
	 display: none;
  }
  .table.th-slim thead th{
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
  }
  .table.th-slim, .table.th-slim td {
    padding: 0.3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table.th-slim-bottom, .table.th-slim-bottom td {
  padding: 0.3rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
}

.table2 {

  border: 1px solid #dee2e6;
}
.table2 th {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #dee2e6;
}
.tableReport {
  padding: 0.3rem;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
}
.tableReport th{
  font-weight: bold;
}
.tableReport th, .tableReport td {
  padding:0.3rem;
  vertical-align: top;
}
.tableReport th {
  border-bottom: 1px solid #dee2e6;
}
.table2 td {
  padding: 0.3rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  // border-bottom: 1px solid #dee2e6;
  // border-left: 1px solid #dee2e6;
  // border-right: 1px solid #dee2e6;
}
  .table.th-slim-mutiHead thead th{
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}
  .padding-label4Form{
	margin-top: .5rem;
	display: inline-block;
	margin-bottom: .5rem;
  }
  .padding-Icon4Form {
      margin-top: .5rem;
      display: inline-block;
      margin-bottom: .5rem;
  }
  .pcoded perfect-scrollbar > .ps {
 
	background: rgb(255, 254, 254) !important;
  }
   .Approve-AWizard aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done:after{
        background-color:rgb(64, 153, 255);;
        color:white !important
  }
  .Approve-CTMANAGE-AWizard aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done:after{ //contract manage use it
    background-color:rgb(64, 153, 255);;
    color:white !important
}
.Approve-CTAPP-AWizard aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done:after{ //contract approve use it
  background-color:rgb(64, 153, 255);;
  color:white !important
}
  .lsit-AWizard aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done:after{
    background-color:rgb(64, 153, 255);;
    color:white !important
}
	.gray-mode aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.editing:after{
        background-color:gray;
        color:#000
	}
	.gray-mode aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing:after{
        color:grey !important;
        border:2px solid grey !important;
	}
	.gray-mode aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done:after{
		color:grey !important;
        border:2px solid grey !important;
    }
    @font-face {
      font-family: myFirstFont;
      src: url(/src/app/pages/Utility/font/ANGSA.ttf);
   }
   .ng-select.ng-select-disabled>.ng-select-container {
    background-color: #e9ecef !important;
}
 #reportContract{
  margin:  0 0 0 40px !important;
  font:myFirstFont !important;
  font-family: myFirstFont !important;
 }
 ::ng-deep blockquote#JSONContainer blockquote.reportContract {
  color: red;
}


.bg-red {
  background-color: #D13B3B;
}

.bg-silver {
  background-color: silver;
}

.table.table-border-space-none{
  border-spacing: 0px;
}


// input:disabled {
  
//   @at-root label {
//     .cr {
//       border: 2px solid gray !important;
//     }

//     .cr i.cr-icon.fa.fa-check.txt-success {
//       color: gray !important;
//     }
//   }
// }