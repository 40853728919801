/*range slider start */

.range-slider {
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    b{
        margin-top: -10px;
    }
}

#RGB {
    height: 10px;
    background: rgb(128, 128, 128);
}

#RC .slider-selection {
    background: #FF8282;
}

#RC .slider-handle {
    background: red;
}

#GC .slider-selection {
    background: #428041;
}

#GC .slider-handle {
    background: green;
}

#BC .slider-selection {
    background: #8283FF;
}

#BC .slider-handle {
    border-bottom-color: blue;
}

#R,
#G,
#B {
    width: 300px;
}

.range-slider-contain {
    position: absolute;
    bottom: 15px;
}

.slider-handle {
    top: -5px;
    background-image: linear-gradient(to bottom, $primary-color 0%, $primary-color 100%);
    box-shadow: none;
}

.slider-tick,
.slider-track {
    background-image: linear-gradient(to bottom, #BDC3C7 0%, #BDC3C7 100%)
}

.slider-handle.custom::before {
    line-height: 15px;
    font-size: 35px;
    color: $primary-color;
}

#slider12a,
#slider12c {
    .slider-track-high {
        background: green;
    }
}

#slider12b,
#slider12c {
    .slider-track-low {
        background: red;
    }
}

#slider12c .slider-selection {
    background: yellow;
}

.slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, lighten($primary-color,10%) 0%, lighten($primary-color,10%) 100%);
}

.slider-tick.in-selection {
    background-image: linear-gradient(to bottom, lighten($primary-color,10%) 0%, lighten($primary-color,10%) 100%);
}

.slider.slider-horizontal {
    .slider-track {
        height: 2px;
    }
    .slider-tick,
    .slider-handle {
        top: -5px;
        cursor: pointer;
    }
}


/*range slider end */


.slider.slider-horizontal .slider-track {
    width: 90%;
    margin-top: -5px;
    top: 50%;
    left: 10px;
}
